<template>
    <div id="app">
    <row3>
      <div class="yinss">
      <section id="partner">
          <h2>Our Partners</h2>
          <p>We are proud to collaborate with the following small companies in Nigeria who help us bring the best local cuisine to you:</p>
          <div class="partner">
              <h3>Fresh Farms</h3>
              <p>Fresh Farms provides us with organic vegetables and fruits, ensuring that our dishes are made with the freshest ingredients.</p>
          </div>
          <div class="partner">
              <h3>Golden Grains</h3>
              <p>Golden Grains supplies us with high-quality rice and grains, which are a staple in many of our traditional dishes.</p>
          </div>
          <div class="partner">
              <h3>Spice World</h3>
              <p>Spice World offers a variety of spices and seasonings that add unique flavors to our meals, making them truly special.</p>
          </div>
      </section>
  </div>
    </row3>
</div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  .partner h3 {
  /* add your styles here */
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: center;
}
  .row5
{
  border-style: solid 2 ;
  margin-top: 10rem;
  overflow: hidden;
  top: 200px;
  text-align: center;
}
  .col3a
  {
      top:180px;
      /*right:10px;*/
      left:250px;
      position:absolute;
      border-style: solid 2 ;
      width:1005px;
  }
      .yinss{
      font-family: Arial, sans-serif;
      padding-left: 300px;
      text-align: center;
      margin-left: -250px;
     
  
  }
.pathner h2{
    font-size: 5.5rem;
    
  }
  section {
      padding: 2rem;
      text-align: center;
  }
  
  .partner {
      background-color: #fff;
      margin: 1rem auto;
      padding: 1rem;
      border: 1px solid #ddd;
      width: 80%;
      max-width: 600px;
  }
  </style>