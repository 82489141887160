<template>
    <row3>
      <div class="yinss">
      <section id="about">
          <h2 >About Us</h2>
          <p>Welcome to Akinola's foods, where we bring you the best of Nigeria's local cuisine. Our mission is to provide delicious, authentic, and high-quality food that celebrates the rich culinary heritage of Nigeria.</p>
          <h3 class="centered">Our Story</h3>
          <p>Founded in 2020, Akinola's Foods limited started with a passion for sharing the diverse flavors of Nigeria with the world. We believe in using fresh, locally sourced ingredients to create dishes that are not only tasty but also nutritious.</p>
          <h3 class="centered">Our Values</h3>
          <div class="superb">
          <ul>
              <li><strong>Quality:</strong> We are committed to providing the highest quality food and service.</li>
              <li><strong>Authenticity:</strong> Our recipes are inspired by traditional Nigerian cuisine.</li>
              <li><strong>Community:</strong> We support local farmers and suppliers.</li>
              <li><strong>Sustainability:</strong> We strive to minimize our environmental impact.</li>
          </ul>
      </div>
      
      </section>
  </div>
</row3>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  <style>
  #about {
  max-width: 1000px; /* adjust the width to your liking */
  margin: 40px auto; /* add some margin for better readability */
  text-align: center; /* center the text */
}
  .centered {
  text-align: center;
  display: block;
  margin: 0 auto;
}
  .col3a
  {
      top:180px;
      /*right:10px;*/
      left:250px;
      position:absolute;
      border-style: solid 2 ;
      width:1005px;
      height:700px;
  }
  .yinss{
      font-family: Arial, sans-serif;
      margin-left: -200px;
  
  }

  section {
      padding: 2rem;
      text-align: center;
  }
  
  h2, h3 {
      color: #333;
      text-align: center;
  }
  
  .superb ul {
      list-style: none;
      padding: 0;
  }
  
  .superb ul li {
      background-color: #fff;
      margin: 0.5rem 0;
      padding: 1rem;
      border: 1px solid #ddd;
  }
  </style>