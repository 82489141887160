<template>
    <div class="container090">
      <div class="terms-conditions-container">
        <h1>Terms and Conditions</h1>
        <div class="terms-conditions-content">
          <h2>1. Introduction</h2>
          <p>Welcome to Akinola Foods! These terms and conditions outline the rules and regulations for the use of our website.</p>
          
          <h2>2. Acceptance of Terms</h2>
          <p>By accessing or using our website, you agree to comply with these terms. If you do not agree, please do not use our website.</p>
          
          <h2>3. Changes to Terms</h2>
          <p>We reserve the right to modify these terms at any time. Changes will be effective immediately upon posting on the website.</p>
          
          <h2>4. User Responsibilities</h2>
          <ul>
            <li>You must provide accurate information when placing orders.</li>
            <li>You are responsible for maintaining the confidentiality of your account information.</li>
          </ul>
          
          <h2>5. Limitation of Liability</h2>
          <p>Akinola Foods shall not be liable for any indirect, incidental, or consequential damages arising from your use of our website or services.</p>
          
          <h2>6. Governing Law</h2>
          <p>These terms shall be governed by and construed in accordance with the laws of Nigeria.</p>
          
          <h2>7. Contact Information</h2>
          <p>For any questions regarding these terms, please contact us at akinolafoods@gmail.com.</p>
        </div>
      </div>
    </div>
    </template>
    
    <script>
    export default {
      name: "TermsAndConditions",
    };
    </script>
    
    <style scoped>
      .container090 {
        margin: 0 auto;
        padding-top: 100px;
        padding-bottom: 20px;
        width: 48rem;
      }
    .terms-conditions-container {
      padding: 20px;
      max-width: 800px;
      margin: auto;
      background-color: white; /* White background for the container */
      border-radius: 8px; /* Rounded corners */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    }
    
    h1 {
      text-align: center;
      margin-bottom: 20px; /* Space below the title */
    }
    
    h2 {
      margin-top: 20px;
    }
    
    .terms-conditions-content {
      line-height: 1.6;
    }
    </style>
    