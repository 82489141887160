<template>
    <div class="row3">
        <div class="container72">
        <h1>Order Confirmation</h1>
        <div class="order-details">
            <h2>Order Details</h2>
            <p><strong>Order Number:</strong> #12345</p>
            <p><strong>Order Date:</strong> August 4, 2023</p>
        </div>
        <p>Thank you for your order! Your order has been received and is being processed. You will receive a confirmation email with your order details shortly.</p>
    </div>
    </div>
  
</template>

<script>
export default {

}
</script>

<style>
    .container72 {
            max-width: 600px;
            margin: 0 auto;
            padding: 40px 0;
        }
        h1 {
            text-align: center;
            margin-bottom: 40px;
        }
        .order-details {
            background-color: #f4f4f4;
            padding: 20px;
            margin-bottom: 20px;
        }
        .order-details h2 {
            margin-top: 0;
        }
        .order-details p {
            margin-bottom: 10px;
        }
</style>